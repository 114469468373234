import Home from "../views/login/index.vue";
const List = () => import("../views/list/index.vue");
const Detail = () => import("../views/detail/index.vue");
const Generate = () => import("../views/generate/index.vue");
const Create = ()=> import("../views/create/index.vue");
const NotFound = () => import("../views/notfound/index.vue");

let routes = [
    {
        path: "/",
        name: "home",
        component: List,
        alias: "/login"
    },
    {
        path: "/list",
        name: "list",
        component: List,
        meta: {
            keepAlive: true
        }
    },
    {
        path: "/detail/:id",
        name: "detail",
        component: Detail
    },
    {
        path: "/generate/:id",
        name: "generate",
        component: NotFound
    },
    {
        path: "/create",
        name: "create",
        component: NotFound
    },{
        path: "/404",
        component: NotFound,
    }
];

export default routes;