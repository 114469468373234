import * as api from "@api"

const actions = {
    async getModelList({commit}, {type, currentPage, pageSize}){
        let result = await api.getList(currentPage, pageSize).catch(e=>console.log(e));
        if(result.status == 0){
            commit("updateList", {
                type,
                data: result.data
            });
        }
    },
    async getGenerateStatus({commit}, id){
        try{
            let result = await api.getGenerateStatus(id);
            if(result.status == 0){
                commit("updateGenerateStatus", result.data)
            }else{
                commit("updateGenerateStatus", {status: "FAILED", progress: 0})
            }
        }catch(e){
            commit("updateGenerateStatus", {status: "FAILED", progress: 0})
        }
    },
    async getProjectInfo({commit}, id){
        try{
            let result = await api.getProjectInfo(id);
            if(result.status == 0){
                commit("updateProjectInfo", result.data);
                return result.data;
            }else{
                console.log("获取详细信息失败")
            }
        }catch{
            console.log("获取详情信息失败")
        }
    }
}

export default actions;