const state = {
    isKeepAlive: true,
    modelList: [],
    generate: {
        progress: 0,
        status: "QUEUED"
    },
    projectInfo: {}
}

export default state;