import axios from "axios";
import { getCookie } from "../utils";

axios.interceptors.request.use(function (config) {
    config.baseURL = "http://text2model-server.lightillusions.com";
    config.baseURL = "http://60.205.156.208:8100";
    config.baseURL = import.meta.env["VITE_baseUrl"];
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = "bearer " +  getCookie("access_token");
    config.timeout = 30 * 1000;
    config.withCredentials = true;
    return config;
}, function (error) {
    return Promise.reject(error);
});

class Fetch {
    constructor(){

    }
    async request(url, method = "get", data = {}, config={}){
        if(method == "get"){
            try{
                return await this.get(url, data, config);
            }catch(error){
                return Promise.reject(error);
            }
        }else if(method == "post"){
            try{
                return await this.post(url, data, config);
            }catch(error){
                return await Promise.reject(error);
            }
        }else{
            return Promise.reject("request type must be get or post.");
        }
    }

    async get(url, data = {}, config={}){
        config.params = data;
        try{
            let response = await axios.get(url, config);
            if(response.status == 200){
                return response.data;
            }else{
                return Promise.reject("server response error.")
            }
        }catch(error){
            return Promise.reject(error)
        }
        
    }
    async post(url, data = {}, config = {}){
        try{
            let response = await axios.post(url, data, config);
            if(response.status == 200){
                return response.data;
            }else{
                return Promise.reject("server response error.")
            }
        }catch(error){
            return Promise.reject(error)
        }
    }
}

export default new Fetch;