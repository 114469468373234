import {createApp} from "vue";
import App from "./app.vue";
import store from "./stores";
import router from "./routers";
import "../src/styles/index.css";

let app = createApp(App);

app.use(store);
app.use(router);

app.mount("#app");