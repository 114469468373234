<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="isKeepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!isKeepAlive" />
  </router-view>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import { useRoute } from "vue-router";

let store = useStore();
let route = useRoute();

let isKeepAlive = computed(() => {
  return route.meta.keepAlive && store.state.isKeepAlive;
});
</script>
