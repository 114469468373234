export function getCookie(name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    return (arr = document.cookie.match(reg)) ? decodeURIComponent(arr[2]) : "";
};

export function setCookie(name, value, expires, path, domain, secure) {
    var cookie = name + '=' + encodeURIComponent(value);
    if (expires) {
        var date = new Date((new Date).setDate(new Date().getDate() + expires));
        cookie += ';expires=' + date.toGMTString();
    }
    if (path) cookie += ';path=' + path;
    if (domain) cookie += ';domain=' + domain;
    if (secure) cookie += ';secure';
    document.cookie = cookie;
}

export function delCookie(name){
    var cookie = name + '=""';
    cookie += ';expires=-1';
    document.cookie = cookie;
}