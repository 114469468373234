import {createRouter, createWebHistory, createWebHashHistory} from "vue-router";

import routes from "./routes";
import { checkLogin, login } from "../api";
import {setCookie} from "../utils";

let router = createRouter({
    history: createWebHistory(),
    routes,

});

router.beforeResolve(async (to, from)=>{
    // 增加自动登录功能
    let autoLogin = async()=>{
       
            let res = await login("demo01", "abcd1234");
            if(res.status == 0 && res.data?.access_token){
                setCookie("access_token", res.data.access_token, "/", );
                router.push({name: "list"})
            }else {
                return "/login"
                
            }  
    }
    try{
            let result = await checkLogin();
            if(result.status != 0){
                autoLogin();
            }else {
                    // 匹配不到跳到list
                   if(to.matched.length ==0) {
                        router.push({path: "/list"})
                        return;
                    }
            }
        
    }catch(error){
            //未登录，开启自动登录
           autoLogin();
    }



 /*    if(to.name != "home"){
        try{
            let result = await checkLogin();
            if(result.status != 0){
                return "/login"
            }
        }catch(error){
            return "/login"
        }
    } */

})

export default router;