import fetch from "./fetch";

export function login(username, password, config = {}){
    return fetch.post(
        "/lime/login", 
        {
            username: username,
            password
        },
        config
    );
}

export function checkLogin(config = {}){
    return fetch.get(
        "/lime/check_login", 
        config
    );
}

export function getList(current_page = 0, page_size = 10, config = {}){
    return fetch.post(
        "/lime/text2model/list_project", 
        {
            current_page,
            page_size
        },
        config
    );
}

export function createModel(project_name = "", prompt = "一个萝卜", negative_prompt = "", generate_type = "MESH", config = {}){
    return fetch.post(
        "/lime/text2model/create_project", 
        {
            project_name,
            prompt,
            negative_prompt,
            generate_type
        },
        config
    );
}

export function getGenerateStatus(id, config = {}){
    return fetch.get(
        "/lime/text2model/query_project_processing_progress/" + id, 
        config
    );
}

export function getProjectInfo(id, config = {}){
    return fetch.get(
        "/lime/text2model/get_project/" + id, 
        config
    );
}