let mutations = {
    updateKeepAlive(state, payload){
        state.isKeepAlive = payload;
    },
    updateList(state, {type, data}){
        if(type == "init"){
            state.modelList = data;
        }else{
            state.modelList.push(...data);
        }
    },
    updateGenerateStatus(state, payload){
        state.generate = payload;
    },
    updateProjectInfo(state, payload){
        state.projectInfo = payload;
    }
}

export default mutations;